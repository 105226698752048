import React from 'react';

interface ProgressBarProps {
  progress: number;
  color?: "default" | "blue" | "green" | "red" | "yellow";
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ 
  progress,
  color = "default" 
}) => {
  // Mapeo de colores para la barra de progreso
  const colorClasses = {
    default: "bg-gray-500 dark:bg-gray-500",
    blue: "bg-blue-500 dark:bg-blue-600",
    green: "bg-green-500 dark:bg-green-600",
    red: "bg-red-500 dark:bg-red-600",
    yellow: "bg-yellow-500 dark:bg-yellow-600",
  };

  return (
    <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-md">
      <div
        className={`h-full rounded-md transition-all ${colorClasses[color]}`}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
